$brand-color: #0078d4;
$white: #fff;
$black: #000;

@mixin formFields {
  label {
    width: 100%;
    font-weight: 500;
    margin-bottom: 0.5rem !important;
    margin-left: 0px !important;
    font-size: 16px !important;
  }
  input {
    display: block;
    width: 50%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc !important;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    -webkit-transition: border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    outline: solid black 0px !important;
    &:focus {
      border: 1px solid $brand-color !important;
    }
  }
}

@mixin formButton {
  display: inline-block;
  padding: 7px 35px 9px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: #fff !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background: $brand-color !important;
  -webkit-border-radius: 3px;
  background-image: none !important;
  border: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.12s linear 0s !important;
  transition: all 0.12s linear 0s !important;
  border-radius: 0px !important;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  &:hover {
    -webkit-box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.16),
      0 6px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.16),
      0 6px 10px 0 rgba(0, 0, 0, 0.12);
  }
}

.banner {
  background-size: cover;
  background-position: 100% 30%;
  height: 420px;
  max-height: 100%;
  display: none;
  &.centerofexcellence-banner {
    background-image: url("../images/banner1.jpg");
    display: block;
  }
}

a {
  color: $brand-color;
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}

.dropdown-menu {
  border: 0px solid #fff;
  margin-top: 0px !important;
  white-space: normal !important;
  //   min-width: 200px !important;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.heading {
  background: #243a5e;
  padding: 75px 0;
  color: $white;
  &.padding-tb-20 {
    padding: 20px 0px;
  }
  background-size: 100%;
  background-position: -10px;
  @media (max-width: 1024px) {
    background-position: bottom;
    background-repeat: no-repeat;
  }
  h1 {
    font-size: 52px !important;
    font-weight: 700;
  }
  h2 {
    font-size: 24px;
  }
}

.card-tc {
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.18);
  background: $white;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 5% 1.5%;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  &:hover {
    box-shadow: 1px 2px 6px $brand-color !important;
  }
  p {
    text-decoration: none;
    color: $black;
    flex: 1;
    margin-bottom: 0px;
    font-weight: 500;
    margin-top: 10px;
    font-size: 18px;
  }
}

.ww-header__container {
  display: none !important;
}
.ww-styled {
  .ww-affirmations {
    height: 0;
  }
  .ww-dsar__branding {
    display: none !important;
  }
  .ww-dsar {
    background: $white !important;
  }
  .ww-dsar__main {
    width: 100% !important;
  }
  .ww-section--padded {
    margin: 0 !important;
  }
}

.p-btn,
.ww-self-service__success-link a {
  @include formButton;
  margin-top: 20px;
}
.ww-self-service__success-link a {
  text-decoration: none !important;
}
.ww-self-service__return-container {
  margin-top: 15px !important;
}
.ww-self-service__success-icon {
  svg {
    float: left;
    margin-right: 10px !important;
  }
  color: $brand-color !important;
}
.margintop-0 {
  margin-top: 0px !important;
}

.ww-self-service__form-submit-container {
  justify-content: start !important;
}

.breachcenter-section {
  .ww-styled .ww-self-service__header {
    justify-content: start !important;
    align-items: start !important;
  }
  .ww-page-wrapper {
    align-items: start !important;
  }
  .ww-self-service-container {
    width: 100% !important;
    max-width: 100% !important;
  }
  .ww-styled .ww-self-service__description {
    font-size: 1rem;
    font-weight: 400;
  }
  .ww-self-service__header {
    font-size: 2.5rem !important;
    font-weight: 500 !important;
  }
}
.form-section {
  max-width: 100%;
  margin: 0px auto;
  &.align-items-start {
    .ww-page-wrapper {
      align-items: start !important;
    }
  }
  .ww-page-wrapper {
    align-items: start !important;
  }
  @media (max-width: 1024px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    max-width: 560px;
  }

  .ww-dsar {
    width: 83%;
    .ww-dsar__branding {
      .top-text {
        color: #282828 !important;
        font-weight: 500 !important;
        font-size: 22px !important;
        line-height: 40px !important;
        padding-bottom: 20px !important;
      }
      .bottom-text {
        font-weight: 500 !important;
        margin-bottom: 20px !important;
      }
    }
    .ww-dsar__fields-grid {
      .p-input {
        margin-bottom: 20px;
        width: 100%;
        max-width: 100%;
        @include formFields;
      }
      @include formFields;
      .p-checkbox > input:focus,
      .p-radio > input:focus {
        border: 0px solid !important;
      }
      label {
        &.p-select-label {
          color: #333f48;
        }
      }
      .p-select {
        margin-bottom: 20px;
        margin-top: 30px;
      }
      .group__container {
        margin: 20px 0px;
      }
    }
    .vertical-center {
      &.ww-dsar__email-label {
        font-weight: 500 !important;
        font-size: 16px !important;
      }
    }
    .ww-dsar__text1 {
      font-weight: 500 !important;
      font-size: 16px !important;
    }
    .selection-container {
      margin-top: 20px;
      margin-bottom: 20px;
      .item-box {
        padding: 10px 8px !important;
        .item-title {
          margin-bottom: 0px !important;
        }
        svg {
          display: none !important;
        }
      }
      .item-box.selected {
        border: 2px solid $brand-color !important;
      }
    }
    .ww-dsar__submission-container {
      .p-btn {
        @include formButton;
      }
    }
  }

  // internal page styles
  .ww-self-service__form-field {
    @include formFields;
    margin-bottom: 20px;
  }

  .ww-self-service__form-submit-container {
    .ww-self-service__form-submit {
      @include formButton;
      input {
        background-color: transparent;
        border: 0px;
        color: #fff;
      }
    }
  }
}
/* ============================ SharedNavbar Styles ========================= */

/* ============================ SubmitRequest Styles ========================= */

.wirewheel-breadcrumb {
  list-style: none;
  margin: 0px;
  padding: 0px;
  li {
    display: inline-block;
    margin-right: 10px;
    a {
      font-weight: 700;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1.25px;
      font-size: 14px;
      color: $brand-color;
      margin-right: 10px;
      &:hover {
        color: $brand-color !important;
      }
    }
    &:not(:last-child)::after {
      content: "-";
      font-weight: 700;
      text-transform: uppercase;
      color: $brand-color;
    }
  }
}

.sub-heading {
  padding: 20px 10px;
  background-color: #f8f4f4;
  p {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-size: 14px;
    display: inline-block;
    margin: 0;
  }
  .sub-heading--wirewheel-anchor {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-size: 14px;
    color: $brand-color;
    &:hover {
      color: $brand-color;
    }
  }
}

.list-item {
  a {
    color: $brand-color;
  }
}

.list-item {
  a:hover {
    color: $brand-color;
    font-weight: 600;
    text-decoration: none;
  }
}

.ww-dsar__fields-grid {
  div:nth-child(4) {
    margin-left: 0.4rem;
    margin-top: -0.4rem;
    div {
      label:first-child {
        font-weight: 600 !important;
        font-size: 13px !important;
      }
    }
  }
}

.p-radio[data-v-6ef98034] {
  font-size: 13px !important;
}

.p-radio {
  span[data-v-6ef98034]::before {
    width: 13px !important;
    height: 13px !important;
    font-weight: 400 !important;
  }
}
.p-radio {
  span[data-v-6ef98034]::after {
    top: -2px !important;
    left: -4px !important;
    width: 11px !important;
    height: 11px !important;
  }
}

@media (min-width: 600px) {
  .ww-styled .ww-dsar__fields-grid[data-v-3c301269] {
    display: block !important;
    width: 50% !important;
  }
}

.ww-dsar__branding,
.ww-header__container {
  display: none !important;
}

.ww-dsar {
  background-color: #fff !important;
}

.ww-styled .ww-dsar[data-v-31159690] {
  width: 100%;
  padding: 25px 0;
}

.ww-styled .ww-dsar__main[data-v-31159690] {
  width: 90%;
  max-width: 700px;
}

.ww-styled .ww-dsar__form-container[data-v-31159690] {
  padding: 25px;
  box-shadow: 0 2px 4px 0;
  background-color: white;
}

.ww-styled .ww-dsar__form-contents[data-v-3c301269] {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.ww-styled .ww-dsar__fields-grid {
  display: block !important;
}

@media (min-width: 600px) {
  .ww-styled .ww-dsar__fields-grid[data-v-3c301269] {
    display: grid;
    grid-template-columns: 40% 40%;
    grid-gap: 25px;
    padding-bottom: 10px;
  }
}

.form-section .ww-dsar .ww-dsar__fields-grid .p-input {
  margin-bottom: 20px;
  width: 100%;
  max-width: 100%;
}

.form-section .ww-dsar .ww-dsar__fields-grid .p-input label {
  width: 100%;
  font-weight: 500;
  margin-bottom: 0.5rem !important;
  margin-left: 0 !important;
  font-size: 16px !important;
}

.form-section .ww-dsar .vertical-center.ww-dsar__email-label,
.form-section .ww-dsar .ww-dsar__text1 {
  font-weight: 500 !important;
  font-size: 16px !important;
}
// ============================== Footer Styles =============================

.wirewheel-footer {
  background: #f2f2f2;
  clear: both;
  width: 100%;
  min-height: 60px;
  vertical-align: middle;
  font-weight: 500 !important;
  color: #616161;
}

// ============================== Mobile Responsiveness =============================

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .heading h1 {
    font-size: 36px !important;
    font-weight: 700;
  }

  h3 {
    font-size: 1.5rem;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .heading h1 {
    font-size: 36px !important;
    font-weight: 700;
  }
  h3 {
    font-size: 1.5rem;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (max-width: 1000px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

.nav-item {
  width: 250px;
}

.nav-link {
  color: $brand-color !important;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    font-weight: 500;
  }
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: normal !important;
  background-color: transparent;
  border: 0;
  font-size: 14px;
  &.active,
  &:active {
    color: #212529;
    text-decoration: none !important;
    background-color: transparent;
  }
}
.dropdown-item:hover {
  background: $brand-color !important;
  color: #ffffff !important;
}

.button {
  background-color: $brand-color !important;
}

.footer-link {
  color: #fff !important;
  text-decoration: none;
  &:hover {
    color: #fff !important;
    font-weight: 700 !important;
  }
}

a {
  color: $brand-color;
  font-weight: bold;
  &:hover {
    text-decoration: underline !important;
  }
}

.ww-styled .p-btn[data-v-6002dc57]:disabled,
.ww-styled .p-btn[disabled][data-v-6002dc57] {
  background-color: #b5b5b5 !important;
  border-color: #b5b5b5 !important;
}

.form-check-label {
  input[type="radio"]:checked + label:after {
    background: $brand-color !important;
  }
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: $brand-color;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

/* optout page styling */

.main-content {
  .main-title {
    font-size: 1.2rem;
  }
}

.form {
  width: 60%;
}

.header-nav {
  background: #fff;
}

@media screen and (max-width: 768px) {
  .form {
    width: 90%;
  }
}

@media screen and (max-width: 991px) {
  .dropdown {
    margin-top: 10px;
  }
  .form {
    width: 90%;
  }
}

.footer-links {
  text-decoration: none;
  color: black !important;
}

.gg-recaptcha {
  width: 304px !important;
  max-width: 304px !important;
  -ms-transform: scale(0.8, 0.8);
  -webkit-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
  margin: 0px;
  padding: 0px;
}

.spinner {
  margin-left: 50%;
  margin-top: 100px;
}

.error-border {
  border: 1px solid #e90721 !important;
  box-sizing: border-box !important;
  box-shadow: inset 0 0 4px #e90721 !important;
}

.form-section
  .ww-self-service__form-submit-container
  .ww-self-service__form-submit:disabled,
.form-section
  .ww-self-service__form-submit-container
  .ww-self-service__form-submit[disabled] {
  background-color: #b5b5b5 !important;
  border-color: #b5b5b5 !important;
}

.cursor {
  cursor: pointer;
}

.ww-styled .ww-section {
  width: 100% !important;
  text-align: justify !important;
}

.ww-styled .ww-self-service {
  display: block !important;
}

.ww-styled .ww-self-service__header {
  justify-content: start !important;
}

.ww-styled .ww-self-service-container {
  width: 100% !important;
  max-width: 100% !important;
  text-align: justify !important;
}
